import "react-app-polyfill/stable";
import "core-js";
import { createRoot } from "react-dom/client";
import React, { useState, useEffect } from "react";
import App from "./App";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import "./App.css";
import { Provider } from "react-redux";
import store from "./store";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { GlobalProvider } from "./GlobalContext";

const Root = () => {
  const [reCaptchaKey, setReCaptchaKey] = useState("");

  useEffect(() => {
    const fetchReCaptchaKey = async () => {
      try {
        const { data } = await axios.get(`${process.env.REACT_APP_API_SERVER_URL}/settings`);
        if (data.length > 0) {
          setReCaptchaKey(data[0].recaptcha_key);
        }
      } catch (error) {
        console.error("Error fetching reCaptcha key:", error);
      }
    };

    fetchReCaptchaKey();
  }, []);


  return (
    <Provider store={store}>
      <GlobalProvider>
        <GoogleReCaptchaProvider
          reCaptchaKey={reCaptchaKey}
          scriptProps={{
            async: false,
            defer: false,
            appendTo: "head",
            nonce: undefined,
          }}
        >
          <App />
        </GoogleReCaptchaProvider>
      </GlobalProvider>
    </Provider>
  );
};

window.addEventListener("DOMContentLoaded", function (e) {
  createRoot(document.getElementById("root")).render(<Root />);
});
