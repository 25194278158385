import { useEffect, useState } from "react";
import axios from "axios";

const useFetch = (url) => {
  const [data, setdata] = useState(null);
  const [loading, setloading] = useState(true);
  const [error, seterror] = useState("");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_SERVER_URL}/${url}`)
      .then((response) => {
        seterror(response.data.error);
        setdata(response.data);
        setloading(false);
      })
      .catch((error) => {
        seterror(error.message);
        setloading(false);
      });
  }, [url]);
  if (error) {
    console.log(`${url} api error`, error);
  }
  return { data, loading };
};

export default useFetch;
